import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/pentech.hu/documentation-of-integrations/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import IframePreview from './IframePreview';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <IframePreview mdxType="IframePreview" />
    <InlineNotification kind={"info"} mdxType="InlineNotification">
    Méretezd át ezt az ablakot, hogy más méretekben is kipróbáld az iframe-et.
    </InlineNotification>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      